﻿@media only screen and (max-width: 600px) {
  @for $i from 1 through 100 {
    .denemescss#{$i} {
      margin-top: #{$i}px !important;
    }
  }
}
@media only screen and (min-device-width : 600px) and (max-device-width : 1024px) {
  @for $i from 1 through 100 {
    .denemescss#{$i} {
      margin-top: #{$i*2}px !important;
    }
  }
}

