// Here you can add other styles


/*
.breadcrumb {
  margin-bottom: 0.1rem !important;
}

.card {
  margin-bottom: 0.1rem !important;
}

.container-fluid {
  padding-right: 0.1rem !important;
  padding-left: 0.1rem !important;
}*/

/*.sidebar
{
  background: #173963 !important;
}

.sidebar {
  .nav-link:hover {
    background: #50b848 !important;
  }
}

.btn-success {
  color: #fff;
  background-color: #50b848 !important;
  border-color: #50b848 !important;
}

[class*="MuiInput-underline"] {

  &:after {
    border-bottom: 2px solid #173963 !important;
  }
}

.app-header
{
  .nav-item {
    min-width: 40px !important;
  }
}

.breadcrumb
{
  margin-bottom: 0.5rem !important;
}

.card
{
  margin-bottom: 0.5rem !important;
}



.btn-info
{
  color: #FFFFFF !important;
}

*/

body {
  font-size: 1rem;
  background-color: #F5F5F5 !important;
  overflow-y: scroll !important;
  padding-right: 0px !important;
}

.main {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.modal-backdrop.show {
  opacity : 0 !important
}


.main .container-fluid {
  padding: 15px !important;
}

a:focus {
  outline: none !important;
}

button:focus {
  outline: none !important;
}

.app-body
{
    margin-top: 76px !important;
}

.app-header {
  height: 76px !important;
}

.app-footer {
  padding-right: 12px !important;
  padding-left: 12px !important;
  padding-bottom: 12px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

paper-button {
  text-transform: none;
}


.custom_collapse {
  overflow: hidden;
  transition: 0.4s max-height;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


// --------------------------------
// AddressInformationStyles.js
// --------------------------------

.customizeTimeline {
  all: initial;
  padding: 5px;
  margin-top: 10px;
}

.danger {
  background-color: #ff5757;
  color: white;
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 20px;
  font-size: 17px;
}

.PaperStyle {
  min-height: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
}

.GridCenterStyle {
  min-height: 250px;
  height: 250px;
  margin-bottom: 12px;
}

.PaperCenterStyle {
  min-height: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.BoxCenterStyle {
  min-height: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.CardActionStyle {
  margin-top: auto;
}

.mr15px {
  margin-right: 15px;
}

.typography1_1_bottom {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
  text-align: left;
  margin-bottom: 11px;
  white-space: break-spaces;
  word-break: break-all;
  justify-content: flex-end;
  display: flex;
}

.typography1_bold {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 20px;
  font-size: 13px;
  margin-left: 61;
  text-align: left;
  margin-bottom: 11px;
  white-space: break-spaces;
  word-break: break-all;
  justify-content: flex-end;
  display: flex;
}

.typography1_useDefault {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: bold;
  line-height: 20px;
  font-size: 13px;
  margin-right: 15px;
  text-align: right;
  margin-bottom: 11px;
  white-space: break-spaces;
  word-break: break-all;
  color: #b1b1b1;
  margin-top: 5px;
}

.typography1_1_noBottom {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: '500';
  line-height: '20px';
  font-size: '13px';
  margin-left: "16px";
  margin-right: "16px";
  text-align: "left";
  white-space: "break-spaces";
  word-break: "break-all";
  max-height: "50px";
  overflow: "hidden";
  text-overflow: "ellipsis";
  display: "-webkit-box";
}

.customizeTimeline {
  all: initial;
  padding: 5px; 
}

.CardMb10 {
  margin-bottom: 15px;
}

.typography1 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 20px;
  font-size: 17px;
  margin-top: 7px;
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;
  margin-bottom: 11px;
}

.typography2 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 13px;
  font-size: 10px;
  margin-top: 3px;
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;
  color: #2582ac;
}

.typography3 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 25px;
  font-size: 21px;
  margin-top: 7px;
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;
  color: #1E1F21;
}

.typography4 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 16px;
  font-size: 13px;
  margin-top: 5px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  text-align: left;
  color: #1E1F21;
}

.typography5 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 13px;
  font-size: 10px;
  margin-top: 5px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  text-align: left;
  color: #2582ac;
}

.activeColor {
  color: #a2cf6e !important;
}

.typography6 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 13px;
  font-size: 10px;
  margin-top: 3px;
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;
  color: #2582ac;
}

.typography_showmore {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 13px;
  font-size: 10px;
  margin-top: 5px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  text-align: left;
}

.typography8 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 13px;
  font-size: 10px;
  margin-top: 3px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  text-align: left;
  color: #ff5757;
}

.typography8_1 {
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 13px;
  font-size: 13px;
  margin-top: 3px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 10px;
  text-align: left;
  color: #ff5757;
}

.selectedAddressCard {
  border: 5px solid #ff5757;
  box-shadow: 0px 3px 6px #00000029;
}

.NoselectedAddressCard {
  border: 5px solid white;
}

.TextFieldCard {
  margin-bottom: 10px;
}

.updateButton {
  font-family: 'Montserrat';
  text-transform: none;
  font-weight: bold;
}

.typography_Packages_Header {
  font-style: normal;
  font-family: 'Montserrat';
  line-height: 24px;
  font-size: 18px;
  color: #1E1F21;
  margin-top: 10px;
  font-weight: bold;
}

// --------------------------------
// AdminContainerStyles.js
// --------------------------------

.container {
  max-width: 5000px;
  padding: 15px;
}

.paper {
  background-color: #1E1F21;
  top: 80px;
  padding-block-end: 80px;
}

.paper2 {
  top: 80px;
}

.expandedMargin {
  margin: 0px !important;
  background-color: #1E1F21;
}

// --------------------------------
// AdminMainPageStyles.js
// --------------------------------

.root {
  width: 50%;
  margin: 0 auto;

  @media screen and (max-width: 900px) {
    width: 80%;
  }
  
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  background-color: #FEFEFE;
}

.root2 {
  width: 300;
  background-color: #FEFEFE;
}

.media {
  height: 0;
  padding-top: 56.25%;
}

.expand {
  margin-left: auto;
  transition: rotate(0deg);
  transition-duration: 0.1s;
}

.expandOpen {
  transform: rotate(180deg);
}

.avatar {
  background-color: transparent;
  width: 30px;
  height: 30px;
  @media screen and (max-width: 0px) {
    width: 24px;
    height: 24px;
  }
}

.text {
  color: #303030;
  text-align: center;
  margin: auto;
}

.menuButton {
  text-align: right;
}

.avatarStyle {
  text-align: center;
  display: flex;
  justify-content: center;
}

.onlineUserTitle {
  text-align: center;
  margin-block-end: 20px;
}

.userType {
  font-weight: bold;
  font-size: 10px;
  margin-top: 5px;
}

.cardStyle {
  border-radius: 5px;
  box-shadow: 3px 3px 6px #f1f1f1;
}
